@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	html,
	body {
		margin: 0;
		font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size: 0.9rem;
		height: 100%;
		overflow: hidden;
		color: #34373d;
	}

	#root {
		height: 100%;
		min-height: 100%;
		display: flex;
		flex-direction: column;
	}

	code {
		font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	}

	.icon-style {
		@apply stroke-1 w-5 h-5 checked:fill-fontColor stroke-fontColor;
	}

	.task-details-style {
		@apply flex flex-col mb-1 bg-white hover:bg-hoverColor rounded;
	}

	.task-details-button-style {
		@apply flex p-4 text-darkerGrey text-sm cursor-pointer;
	}

	.list-background {
		background: linear-gradient(180deg, #fff, #fff 3.5rem, #eaeaea 3.5rem, #eaeaea 3.5rem);
		background-size: 100% 3.6rem;
	}

	.button-primary {
		@apply bg-blue text-white w-full border border-transparent hover:bg-blue;
	}

	.button-secondary {
		@apply bg-red text-white ml-2 w-full border border-transparent hover:bg-rose-800;
	}

	.button-outline {
		@apply bg-none text-fontColor border border-grey mt-3 w-full bg-white hover:bg-gray-50 sm:mt-0;
	}

	.button-disabled {
		@apply disabled:bg-lightBlue cursor-not-allowed;
	}

	.button-outlineWhite {
		@apply bg-none text-white border border-white;
	}

	.input-styles {
		@apply w-full outline-none text-lg md:text-sm appearance-none rounded relative block border border-border placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue focus:border-blue focus:z-10 bg-white;
	}

	.input-error-styles {
		@apply text-sm relative text-blue left-2;
	}

	.eye-icon {
		@apply icon-style text-fontColor absolute right-5 top-8;
	}
}
